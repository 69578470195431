import { Switch, useTheme } from "@nextui-org/react";
import { useTheme as useNextTheme } from "next-themes";
import { useEffect, useState } from "react";
import { MoonIcon } from "../../icons/MoonIcon";
import { SunIcon } from "../../icons/SunIcon";

export function ThemeSwitcher() {
  const [mounted, setMounted] = useState(false);
  const { setTheme: setNextTheme } = useNextTheme();
  const { type: currentTheme } = useTheme();

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) return null;

  return (
    <div>
      <Switch
        checked={currentTheme === "dark"}
        shadow
        color='warning'
        size='lg'
        onChange={(e) => setNextTheme(e.target.checked ? "dark" : "light")}
        iconOff={<SunIcon filled />}
        iconOn={<MoonIcon filled />}
      />
    </div>
  );
}
