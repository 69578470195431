import { Button, Modal, Spacer, Text } from "@nextui-org/react";
import OpenAI from "openai";
import { useEffect, useState } from "react";
import { TypeAnimation } from "react-type-animation";
import { HeartIcon } from "../../icons/HeartIcon";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

export const CharacterModal = (props) => {
  const [buttonClicked, setButtonClicked] = useState(false);
  const [count, setCount] = useState(0);
  const [generatedImage, setGeneratedImage] = useState(null);
  const { modalOpen, closeModal, selectedCharacter, result } = props;
  const [heartCount, setHeartCount] = useState(0);
  const [error, setError] = useState(null);
  const [spokenAudioUrl, setSpokenAudioUrl] = useState(null);

  /* useEffect(() => {
    async function generateImage() {
      try {
        const response = await openai.images.generate({
          model: "dall-e-3",
          prompt: `A photograph of this as a scene "${result}"`, // Trimming result to the first 500 characters
          n: 1,
          size: "1024x1024",
        });
        const imageUrl = response.data[0].url;
        setGeneratedImage(imageUrl);
      } catch (error) {
        console.error("Error generating image:", error);
      }
    }

    if (selectedCharacter) {
      generateImage();
    }
  }, [selectedCharacter, result]);
  
  */

  useEffect(() => {
    if (selectedCharacter) {
      fetch(`/api/hearts?characterId=${selectedCharacter.id}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          if (data.success) {
            setHeartCount(data.heartCount);
          } else {
            setError(data.error);
          }
        })
        .catch((error) => {
          console.error("Error fetching heart count:", error);
          setError("Error fetching heart count");
        });
    }
  }, [selectedCharacter]);
  const generateSpokenAudio = async (text) => {
    try {
      const response = await openai.audio.speech.create({
        model: "tts-1",
        voice: "shimmer",
        input: text,
      });
      const audioBlob = await response.blob();
      const audioUrl = URL.createObjectURL(audioBlob);
      setSpokenAudioUrl(audioUrl);
    } catch (error) {
      console.error("Error generating spoken audio:", error);
    }
  };

  // Trigger TTS generation when the result variable changes
  /*
  useEffect(() => {
    if (result) {
      generateSpokenAudio(result);
    }
  }, [result]);
  */

  const handleHeartClick = () => {
    if (!selectedCharacter) return;

    const updatedHeartCount = heartCount + 1;

    setHeartCount(updatedHeartCount); // Update the UI immediately

    // Log characterId and updatedHeartCount
    console.log("Character ID:", selectedCharacter.id);
    console.log("Updated Heart Count:", updatedHeartCount);

    fetch("/api/hearts", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        characterId: selectedCharacter.id,
        heartCount: updatedHeartCount,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (!data.success) {
          throw new Error(data.error || "Unknown error");
        }
        // Optionally update state or UI if needed
      })
      .catch((error) => {
        console.error("Error saving heart count:", error);
        setError("Error saving heart count: " + error.message);
      });
  };

  // Play audio when spokenAudioUrl changes
  useEffect(() => {
    if (spokenAudioUrl) {
      const audio = new Audio(spokenAudioUrl);
      audio.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
    }
  }, [spokenAudioUrl]);

  return (
    <Modal
      blur
      open={modalOpen}
      onClose={closeModal}
      variant='secondary'
      onDoubleClick={closeModal}
      width='600px'
      aria-labelledby='modal-title'
      aria-describedby='modal-description'>
      <Modal.Body>
        {selectedCharacter ? (
          <>
            <Modal.Header>
              <Text h3>{selectedCharacter.name}</Text>
              <Spacer x={2.0} />
              <Button light color='black' auto onPress={handleHeartClick}>
                <HeartIcon filled />
              </Button>
              <Text>{heartCount} hearts</Text>
            </Modal.Header>
            <TypeAnimation
              style={{
                display: "block",
              }}
              sequence={[result]}
              speed={85}
              repeat={0}
              cursor={false}
            />
            {spokenAudioUrl && (
              <audio controls>
                <source src={spokenAudioUrl} type='audio/mpeg' />
                Your browser does not support the audio element.
              </audio>
            )}
          </>
        ) : null}
        <img src={generatedImage} />
      </Modal.Body>
    </Modal>
  );
};
