import {
  Card,
  Grid,
  Input,
  Pagination,
  Row,
  Spacer,
  Text,
} from "@nextui-org/react";
import { useState } from "react";
export function CharacterGrid({ characters, handleCardPress }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(18);
  const [query, setQuery] = useState("");
  const [show, setShow] = useState(false);
  return (
    <div>
      <Spacer y={2} />
      <Input
        clearable
        underlined
        labelPlaceholder='Search...'
        initialValue={""}
        onChange={(event) => setQuery(event.target.value)}
      />
      <Spacer y={2} />
      <Grid.Container gap={2}>
        {characters
          .filter((character) =>
            character.name.toLowerCase().includes(query.toLowerCase())
          )
          .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
          .map((character, index) => (
            <Grid xs={6} sm={4} md={3} lg={2} responsive='true' key={index}>
              <Card
                className='floating'
                isPressable
                isHoverable
                onPress={() => handleCardPress(character)}>
                <Card.Body css={{ p: 0, border: 5 }}>
                  <Card.Image
                    src={character.image}
                    objectFit='cover'
                    width='100%'
                    aria-labelledby='modal-title'
                    aria-describedby='modal-description'
                  />
                </Card.Body>
                <Card.Footer css={{ justifyItems: "flex-start" }}>
                  <Row wrap='wrap' justify='space-between' align='center'>
                    <Text b>{character.name}</Text>
                    <Text
                      css={{
                        color: "$accents7",
                        fontWeight: "$semibold",
                        fontSize: "$sm",
                      }}>
                      {character.status + " " + character.location.name}
                    </Text>
                  </Row>
                </Card.Footer>
              </Card>
            </Grid>
          ))}
        <Spacer y={2} />
        <Grid xs={12} justify='center'>
          <Pagination
            rounded='true'
            boundaries={1}
            controls={false}
            shadow
            color='warning'
            initialPage={currentPage}
            total={Math.ceil(characters.length / itemsPerPage)}
            onChange={(page) => setCurrentPage(page)}
          />
        </Grid>
      </Grid.Container>
    </div>
  );
}
