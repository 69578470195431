import {
  Badge,
  Image,
  Link,
  NextUIProvider,
  Text,
  createTheme,
  styled,
} from "@nextui-org/react";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import Flow from "./compontents/ui/Flow";
import FlowDown from "./compontents/ui/FlowDown";
import { ThemeSwitcher } from "./compontents/ui/ThemeSwitcher";
import "./styles.css";
const lightTheme = createTheme({
  type: "light",
  theme: {
    colors: {}, // optional
  },
});

const darkTheme = createTheme({
  type: "dark",
  theme: {
    colors: {}, // optional
  },
});

const StyledApp = styled("div", {
  dflex: "center",
  flexDirection: "column",
  minHeight: "100vh",
});

export default function App() {
  return (
    <NextThemesProvider
      defaultTheme='dark'
      attribute='class'
      value={{
        light: lightTheme.className,
        dark: darkTheme.className,
      }}>
      <NextUIProvider>
        <ThemeSwitcher />
        <StyledApp className='App'>
          <Text h2>
            <Link href='/' color='text'>
              Citadel{"  "}
              <Badge disableOutline='false' content={""} shape='circle'>
                <Image
                  width={100}
                  height={100}
                  src='https://i.ibb.co/5cVGFQw/logo-1.png'
                />
              </Badge>
            </Link>
          </Text>

          <Text
            h1
            size={25}
            css={{
              textGradient: "45deg, $yellow600 -20%, $red600 100%",
            }}
            weight='bold'>
            OpenAI generated short stories for Rick & Morty
          </Text>
          <Flow />
          <FlowDown />
        </StyledApp>
      </NextUIProvider>
    </NextThemesProvider>
  );
}
