import { Grid, Loading, Spacer } from "@nextui-org/react";
import OpenAI from "openai";
import { useEffect, useState } from "react";
import { CharacterGrid } from "./CharacterGrid";
import { CharacterModal } from "./CharacterModal";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true, // defaults to process.env["OPENAI_API_KEY"]
});

export default function Flow() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [characters, setCharacters] = useState([]);
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [characterData, setCharacterData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [result, setResult] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchCharacters(apiUrl) {
      try {
        const res = await fetch(apiUrl);
        const result = await res.json();
        setCharacters((characters) => [...characters, ...result.results]);
        if (result.info.next) {
          fetchCharacters(result.info.next);
        }
      } catch (error) {
        setError(error);
      }
      setIsLoaded(true);
    }
    fetchCharacters("https://rickandmortyapi.com/api/character/");
  }, []);

  const handleCardPress = async (character) => {
    try {
      setIsLoading(true);

      let promptContent = `Craft an interesting story featuring ${character.name}, in the style of the show Rick and Morty. Add dialogue. If necessary, reveal they are a/an ${character.species} from ${character.location.name}, and/or the fact they are ${character.gender} as part of the story. Make the story as random as possible.`;

      if (character.status === "Dead") {
        promptContent += ` ${character.name} is dead. Tell a story about their demise.`;
      }

      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "user",
            content: promptContent,
          },
        ],
        model: "gpt-3.5-turbo-0125",
      });

      setSelectedCharacter(character);
      setCharacterData({
        name: character.name,
        species: character.species,
        status: character.status,
        location: character.location.name,
        gender: character.gender,
      });
      setModalOpen(true);
      setResult(completion.choices[0].message.content.trim());
    } catch (error) {
      // Adjust error handling logic
      console.error(`Error with OpenAI API request: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <Spacer y={2.0} />
      {isLoading ? (
        <Loading type='points' color='warning'>
          <h2>Generating a story</h2>
        </Loading>
      ) : (
        <Grid.Container>
          <CharacterGrid
            characters={characters}
            handleCardPress={handleCardPress}
          />
          <CharacterModal
            modalOpen={modalOpen}
            handleCardPress={handleCardPress}
            closeModal={closeModal}
            selectedCharacter={selectedCharacter}
            characterData={characterData}
            result={result}
            isLoading={isLoading}
          />
        </Grid.Container>
      )}
    </div>
  );
}
