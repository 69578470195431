import { Text } from "@nextui-org/react";

export default function FlowDown() {
  return (
    <div
      style={{
        height: "250px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <Text
        size={20}
        css={{
          textGradient: "45deg, $yellow600 -20%, $red600 100%",
          textAlign: "center",
        }}
        weight='bold'>
        this Citadel project is OpenAI-inspired tool that aims to create a
        single world built with all the generated stories
      </Text>
    </div>
  );
}
